export default function getBrazilUF(name, options = {}) {
    const { toUF = false } = options;

    if (toUF) return stateToUfList[name];
    return ufList[name && name.toUpperCase()];
}

const ufList = {
    RO: "Rondônia",
    AC: "Acre",
    AM: "Amazonas",
    RR: "Roraima",
    PA: "Pará",
    AP: "Amapá",
    TO: "Tocantins",
    MA: "Maranhão",
    PI: "Piauí",
    CE: "Ceará",
    RN: "Rio Grande do Norte",
    PB: "Paraíba",
    PE: "Pernambuco",
    AL: "Alagoas",
    SE: "Sergipe",
    BA: "Bahia",
    MG: "Minas Gerais",
    ES: "Espírito Santo",
    RJ: "Rio de Janeiro",
    SP: "São Paulo",
    PR: "Paraná",
    SC: "Santa Catarina",
    RS: "Rio Grande do Sul",
    MS: "Mato Grosso do Sul",
    MT: "Mato Grosso",
    GO: "Goiás",
    DF: "Distrito Federal",
};

const stateToUfList = {
    Rondônia: "RO",
    Acre: "AC",
    Amazonas: "AM",
    Roraima: "RR",
    Pará: "PA",
    Amapá: "AP",
    Tocantins: "TO",
    Maranhão: "MA",
    Piauí: "PI",
    Ceará: "CE",
    "Rio Grande do Norte": "RN",
    Paraíba: "PB",
    Pernambuco: "PE",
    Alagoas: "AL",
    Sergipe: "SE",
    Bahia: "BA",
    "Minas Gerais": "MG",
    "Espírito Santo": "ES",
    "Rio de Janeiro": "RJ",
    "São Paulo": "SP",
    Paraná: "PR",
    "Santa Catarina": "SC",
    "Rio Grande do Sul": "RS",
    "Mato Grosso do Sul": "MS",
    "Mato Grosso": "MT",
    Goiás: "GO",
    "Distrito Federal": "DF",
};
